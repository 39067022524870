export default defineNuxtPlugin(() => {
  // Get the Clarity ID from the environment variable
  const clarityID = import.meta.env.VITE_CLARITY_ID;

  if (!clarityID) {
    console.warn('Clarity ID not provided');
    return;
  }

  // Add Clarity pixel script to the head
  const clarityScript = document.createElement('script');
  clarityScript.type = 'text/javascript';
  clarityScript.async = true;

  // Define the Clarity pixel script content with dynamic ID
  clarityScript.innerHTML = `
    (function (c, l, a, r, i, t, y) {
      c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
      t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", "${clarityID}");
  `;

  // Append the Clarity script to the head
  document.head.appendChild(clarityScript);
});
