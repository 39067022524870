import { defineNuxtPlugin } from '#app/nuxt'
import { LazyCheckbox, LazyDeferredContent, LazyPanel, LazyDialog, LazyOverlayPanel, LazySidebar, LazyToast, LazyCarousel, LazySkeleton, LazyProgressSpinner } from '#components'
const lazyGlobalComponents = [
  ["Checkbox", LazyCheckbox],
["DeferredContent", LazyDeferredContent],
["Panel", LazyPanel],
["Dialog", LazyDialog],
["OverlayPanel", LazyOverlayPanel],
["Sidebar", LazySidebar],
["Toast", LazyToast],
["Carousel", LazyCarousel],
["Skeleton", LazySkeleton],
["ProgressSpinner", LazyProgressSpinner],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
