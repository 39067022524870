export default defineNuxtPlugin(() => {
  // Add jQuery script to the head
  const jqueryScript = document.createElement('script');
  jqueryScript.src = 'https://code.jquery.com/jquery-3.6.0.min.js';
  jqueryScript.async = false;
  jqueryScript.onload = () => {
    // After jQuery is loaded, add the GTM script
    const ID = import.meta.env.VITE_GTAG_ID;
    const gtmScript = document.createElement('script');
    gtmScript.async = true;
    gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${ID}`;
    document.head.appendChild(gtmScript);

    // Add GTM noscript to the body
    const noscript = document.createElement('noscript');
    noscript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${ID}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    document.body.appendChild(noscript);
  };

  document.head.appendChild(jqueryScript);
});
