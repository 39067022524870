const redirects = [
  {
    pattern: /^\/category\/insurance(\/.*)?$/,
    target: '/categoria/assicurazioni$1',
  },
  {
    pattern: /^\/category\/energy(\/.*)?$/,
    target: '/categoria/energia$1',
  },
  {
    pattern: /^\/category\/telco(\/.*)?$/,
    target: '/categoria/telefonia$1',
  },
  {
    pattern: /^\/categoria\/insurance(\/.*)?$/,
    target: '/categoria/assicurazioni$1',
  },
  {
    pattern: /^\/categoria\/energy(\/.*)?$/,
    target: '/categoria/energia$1',
  },
  {
    pattern: /^\/categoria\/telco(\/.*)?$/,
    target: '/categoria/telefonia$1',
  },
];


export default defineNuxtRouteMiddleware((to, from) => {
  // Loop through the redirects to find a matching pattern
  for (const redirect of redirects) {
    const match = to.path.match(redirect.pattern);
    if (match) {
      // Replace the path using the target pattern
      const newPath = to.path.replace(redirect.pattern, redirect.target);
      return navigateTo(newPath);
    }
  }

  // Scroll to the top of the page on navigation if desired
  if (to.path !== from.path && process.client) {
    window.scrollTo(0, 0);
  }
})